import { createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#FEBC11",
    },
    secondary: {
      main: "#330101",
    },
    success: {
      main: "#2e7d32",
    },
    info: {
      main: "#085909",
    },
    warning: {
      main: "#ed6c02",
    },
    error: {
      main: "#d32f2f",
    },
    text: {
      primary: "#ffffff",
      secondary: "#FEBC11",
      disabled: "#616161",
    },
    background: {
      default: "#121212",
      paper: "#333131",
    },
    borderColor: "#FEBC11",
    action: {
      active: "#ffffff",
      hover: "#616161",
      hoverOpacity: "50%",
      selected: "#616161",
      selectedOpacity: "100%",
    },
  },

  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"San Francisco"',
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: 16,
    color: "#ffffff",
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: "#222222",
          borderRadius: "8px",
          minHeight: "40px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          alignItems: "start",
          justifyContent: "start",
          textAlign: "left",
          textTransform: "none",
          minHeight: 0,
          padding: "10px 20px",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          color: "white",
          "&.Mui-selected": {
            backgroundColor: "#5d5d5d",
            color: "rgb(0,221,255)",
            borderLeft: "solid 2px",
            "&.Mui-focusVisible": {
              background: "rgb(0,221,255)",
            },
            "& .MuiListItemIcon-root": {
              color: "rgb(0,221,255)",
            },
          },
        },
      },
    },
  },
});

export default darkTheme;
