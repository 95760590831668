import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Url from "../../APIConfig";

import { setupInterceptorsTo } from "reduxStore/Authentication/Interceptors";
import {
  SearchSubscriptionByOrgIdBody,
  SearchSubscriptionByOrgIdResponse,
} from "./subscriptionListModel";

type InitialState = {
  loading: boolean;
  response: SearchSubscriptionByOrgIdResponse;
  error: string;
};
const initialState: InitialState = {
  loading: false,
  response: {} as SearchSubscriptionByOrgIdResponse,
  error: "",
};

export const searchSubscriptionByOrgId = createAsyncThunk<
  SearchSubscriptionByOrgIdResponse,
  SearchSubscriptionByOrgIdBody
>(
  "searchValue/searchSubscriptionByOrgId",
  async (payload: SearchSubscriptionByOrgIdBody, { rejectWithValue }) => {
    const headers = {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${payload.access}`,
    };
    return await setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/searchapp/api/searchPatientInPatientOrganization/searchPatientListInPatientOrganization?page=1&size=50`,
        payload.body,
        { headers }
      )
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(
          error.response.data.message ||
            error.response.data[0].detail ||
            error.response.data.profilePicture[0] ||
            "Something went wrong"
        )
      );
  }
);

const searchSubscriptionByOrgIdSlice = createSlice({
  name: "searchValue",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchSubscriptionByOrgId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      searchSubscriptionByOrgId.fulfilled,
      (state, action: PayloadAction<SearchSubscriptionByOrgIdResponse>) => {
        state.loading = false;
        state.response = action.payload;
        state.error = "";
      }
    );
    builder.addCase(searchSubscriptionByOrgId.rejected, (state, action) => {
      state.loading = false;
      state.response = {} as SearchSubscriptionByOrgIdResponse;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default searchSubscriptionByOrgIdSlice.reducer;
