import * as React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import moment from "moment";
import { Calendar, momentLocalizer, stringOrDate } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
export default function Attendance() {
  const now = new Date();
  const localizer = momentLocalizer(moment);
  const events = [
    {
      id: 0,
      title: "All Day Event very long title",
      allDay: true,
      start: new Date(2022, 3, 0),
      end: new Date(2022, 3, 1),
    },
    {
      id: 1,
      title: "Long Event",
      start: new Date(2022, 3, 7),
      end: new Date(2022, 3, 10),
    },

    {
      id: 2,
      title: "DTS STARTS",
      start: new Date(2021, 2, 13, 0, 0, 0),
      end: new Date(2021, 2, 20, 0, 0, 0),
    },

    {
      id: 3,
      title: "DTS ENDS",
      start: new Date(2021, 10, 6, 0, 0, 0),
      end: new Date(2021, 10, 13, 0, 0, 0),
    },

    {
      id: 4,
      title: "Some Event",
      start: new Date(2022, 3, 9, 0, 0, 0),
      end: new Date(2022, 3, 10, 0, 0, 0),
    },
    {
      id: 5,
      title: "Conference",
      start: new Date(2022, 3, 11),
      end: new Date(2022, 3, 13),
      desc: "Big conference for important people",
    },
    {
      id: 6,
      title: "Meeting",
      start: new Date(2022, 3, 12, 10, 30, 0, 0),
      end: new Date(2022, 3, 12, 12, 30, 0, 0),
      desc: "Pre-meeting meeting, to prepare for the meeting",
    },
    {
      id: 7,
      title: "Lunch",
      start: new Date(2022, 3, 12, 12, 0, 0, 0),
      end: new Date(2022, 3, 12, 13, 0, 0, 0),
      desc: "Power lunch",
    },
    {
      id: 8,
      title: "Meeting",
      start: new Date(2022, 3, 12, 14, 0, 0, 0),
      end: new Date(2022, 3, 12, 15, 0, 0, 0),
    },
    {
      id: 9,
      title: "Happy Hour",
      start: new Date(2022, 3, 12, 17, 0, 0, 0),
      end: new Date(2022, 3, 12, 17, 30, 0, 0),
      desc: "Most important meal of the day",
    },
    {
      id: 10,
      title: "Dinner",
      start: new Date(2022, 3, 12, 20, 0, 0, 0),
      end: new Date(2022, 3, 12, 21, 0, 0, 0),
    },
    {
      id: 11,
      title: "Planning Meeting with Paige",
      start: new Date(2022, 3, 13, 8, 0, 0),
      end: new Date(2022, 3, 13, 10, 30, 0),
    },
    {
      id: 11.1,
      title: "Inconvenient Conference Call",
      start: new Date(2022, 3, 13, 9, 30, 0),
      end: new Date(2022, 3, 13, 12, 0, 0),
    },
    {
      id: 11.2,
      title: "Project Kickoff - Lou's Shoes",
      start: new Date(2022, 3, 13, 11, 30, 0),
      end: new Date(2022, 3, 13, 14, 0, 0),
    },
    {
      id: 11.3,
      title: "Quote Follow-up - Tea by Tina",
      start: new Date(2022, 3, 13, 15, 30, 0),
      end: new Date(2022, 3, 13, 16, 0, 0),
    },
    {
      id: 12,
      title: "Late Night Event",
      start: new Date(2022, 3, 17, 19, 30, 0),
      end: new Date(2022, 3, 18, 2, 0, 0),
    },
    {
      id: 12.5,
      title: "Late Same Night Event",
      start: new Date(2022, 3, 17, 19, 30, 0),
      end: new Date(2022, 3, 17, 23, 30, 0),
    },
    {
      id: 13,
      title: "Multi-day Event",
      start: new Date(2022, 3, 20, 19, 30, 0),
      end: new Date(2022, 3, 22, 2, 0, 0),
    },
    {
      id: 14,
      title: "Today",
      start: new Date(new Date().setHours(new Date().getHours() - 3)),
      end: new Date(new Date().setHours(new Date().getHours() + 3)),
    },
    {
      id: 15,
      title: "Attend the gym session",
      start: now,
      end: now,
    },
    {
      id: 16,
      title: "Video Record",
      start: new Date(2022, 3, 14, 15, 30, 0),
      end: new Date(2022, 3, 14, 19, 0, 0),
    },
    {
      id: 17,
      title: "Dutch Song Producing",
      start: new Date(2022, 3, 14, 16, 30, 0),
      end: new Date(2022, 3, 14, 20, 0, 0),
    },
    {
      id: 18,
      title: "Itaewon Halloween Meeting",
      start: new Date(2022, 3, 14, 16, 30, 0),
      end: new Date(2022, 3, 14, 17, 30, 0),
    },
    {
      id: 19,
      title: "Online Coding Test",
      start: new Date(2022, 3, 14, 17, 30, 0),
      end: new Date(2022, 3, 14, 20, 30, 0),
    },
    {
      id: 20,
      title: "An overlapped Event",
      start: new Date(2022, 3, 14, 17, 0, 0),
      end: new Date(2022, 3, 14, 18, 30, 0),
    },
    {
      id: 21,
      title: "Phone Interview",
      start: new Date(2022, 3, 14, 17, 0, 0),
      end: new Date(2022, 3, 14, 18, 30, 0),
    },
    {
      id: 22,
      title: "Cooking Class",
      start: new Date(2022, 3, 14, 17, 30, 0),
      end: new Date(2022, 3, 14, 19, 0, 0),
    },
    {
      id: 23,
      title: "Go to the gym",
      start: new Date(2022, 3, 14, 18, 30, 0),
      end: new Date(2022, 3, 14, 20, 0, 0),
    },
  ];
  const handleSelect = (event: {
    start: stringOrDate;
    end: stringOrDate;
    slots: Date[] | string[];
    action: "select" | "click" | "doubleClick";
  }) => {
    console.log(event);
  };
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
      px={2}
      py={2}
    >
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={4}
        >
          <Grid item>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Attendance
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              Attendance Management SystemManage Your Attendance Here.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={9} lg={9}>
        <Calendar
          selectable
          localizer={localizer}
          defaultDate={new Date()}
          defaultView="month"
          events={events}
          style={{ height: "85vh" }}
          step={15}
          timeslots={4}
          onSelectEvent={(event) => alert(event.title)}
          onSelectSlot={(event) => handleSelect(event)}
        />
      </Grid>
    </Grid>
  );
}
