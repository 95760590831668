import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import MWPageTitle from "components/MWPageTitle";
import {
  ViewDemographics,
  ViewSubscriberDetailsById,
} from "reduxStore/subscriberDetails/subscriberDetailsModel";
import { useAppDispatch, useAppSelector } from "reduxStore/hooks";
import { viewSubscriberDetailsById } from "reduxStore/subscriberDetails/subscriberDetailsSlice";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import SubscriberStatus from "pages/SubscriptionStatus";
export default function SubscriberDetails() {
  const dispatch = useAppDispatch();
  const { patientId } = useParams() as {
    patientId: string;
  };
  const Bearer = localStorage.getItem("access");

  const viewDemographicsBody = {
    healthRecordId: patientId,
  } as ViewDemographics;

  const viewSubscriberDetailsByIdBody = {
    body: viewDemographicsBody,
    access: Bearer!,
  } as ViewSubscriberDetailsById;

  useEffect(() => {
    dispatch(viewSubscriberDetailsById(viewSubscriberDetailsByIdBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const viewDemographicsRes = useAppSelector(
    (state) => state.subscriberDetails
  );
  const viewDemographicsValue = viewDemographicsRes?.response?.personalDetails;

  return (
    <Container maxWidth="xl">
      <Grid
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
        py={2}
      >
        <MWPageTitle
          backButton={true}
          title={`Patient Details of ${
            viewDemographicsValue?.firstname +
            " " +
            viewDemographicsValue?.lastname
          }`}
        />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="strech"
          spacing={1}
          py={2}
        >
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Card style={{ borderRadius: 20 }}>
              <CardHeader
                title={
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        Demographics
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <img
                      src={viewDemographicsValue?.profilePicture?.document}
                      srcSet={viewDemographicsValue?.profilePicture?.document}
                      alt=""
                      loading="lazy"
                      style={{
                        maxWidth: "180px",
                        maxHeight: "180px",
                        flex: 1,
                      }}
                    />

                    {/* <Avatar sx={{ width: 120, height: 120 }} /> */}
                  </Grid>
                  <Grid item xs={12} sm={6} md={8} lg={8}>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      spacing={2}
                    >
                      <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Typography>
                          Name:{" "}
                          {viewDemographicsValue?.firstname +
                            " " +
                            viewDemographicsRes?.response?.personalDetails
                              ?.lastname}
                        </Typography>
                        <Typography>
                          Gender:{" "}
                          {
                            viewDemographicsRes?.response?.personalDetails
                              ?.gender?.label
                          }
                        </Typography>
                        <Typography>
                          Age:{" "}
                          {dayjs(new Date()).diff(viewDemographicsValue?.dob, "year")}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  spacing={1}
                >
                  <Grid item>
                    <Button
                      variant="text"
                      // endIcon={<ArrowRightAltIcon />}
                      sx={{ textTransform: "none" }}
                      // onClick={() => handleDemographicToggle()}
                    >
                      View All
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6}>
            <SubscriberStatus />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
