import React from "react";
import AppFrame from "./components/AppFrame";
import { ColorModeContextProvider } from "contexts";
import { CssBaseline } from "@mui/material";

const App = () => {
  return (
    <ColorModeContextProvider>
      <CssBaseline />
      <AppFrame />
    </ColorModeContextProvider>
  );
};

export default App;
