import * as React from "react";
import {
  Grid,
  Box,
  Typography,
  Tabs,
  Tab,
  Avatar,
  IconButton,
} from "@mui/material";
import LoginImage from "images/sw-jd.png";
import icon from "images/sportswisdom.png";
import SigninwithPassword from "pages/OnBoarding/component/SigninPassword/index";
import SigninwithOtp from "pages/OnBoarding/component/SigninOtp/index";
import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";
import { ColorModeContext } from "contexts";
import { useContext } from "react";
export default function SignIn() {
  const [value, setValue] = React.useState(0);
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { mode, setMode } = useContext(ColorModeContext);

  React.useEffect(() => {}, []);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        wrap="wrap-reverse"
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          display={{ xs: "none", lg: "block" }}
        >
          <Avatar
            variant={"square"}
            alt="The image"
            src={LoginImage}
            style={{
              width: "100%",
              height: "100vh",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            p={2}
          >
            <IconButton onClick={setMode}>
              {mode === "light" ? <DarkModeOutlined /> : <LightModeOutlined />}
            </IconButton>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            p={2}
          >
            <Grid item>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                  border: 1,
                  borderColor: "text.secondary",
                  borderRadius: 2,
                }}
              >
                <Grid item pt={2}>
                  <img alt="icon" src={icon} width="60" height="60" />
                </Grid>
                <Grid item>
                  <Typography component="h1" variant="h5">
                    Sign In to
                  </Typography>
                </Grid>
                <Grid item pt={2}>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    sportsWISDOM
                  </Typography>
                </Grid>

                <Grid item>
                  <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                    <Tabs value={value} onChange={handleChange} centered>
                      <Tab
                        label="Sign in with OTP"
                        style={{ textTransform: "none", fontSize: "18px" }}
                      />
                      <Tab
                        label="Sign in with password"
                        style={{ textTransform: "none", fontSize: "18px" }}
                      />
                    </Tabs>
                  </Box>
                  <Box>
                    {value === 0 && <SigninwithOtp />}
                    {value === 1 && <SigninwithPassword />}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
