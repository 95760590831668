import * as React from 'react';
import { Navigate } from 'react-router-dom'

interface Props {
  component: React.ComponentType
  path?: string
}

export const PrivateRoutes: React.FC<Props> = ({ component: RouteComponent }) => {
  const token = localStorage.getItem('access')
  if (!token) {
    return <Navigate to="/login" />
  }
  return <RouteComponent />
}
