import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(
  date: string,
  exerciseDay: string,
  exercisename: string,
  reps: number,
  sets: number,
  comments: String
) {
  return { date, exerciseDay, exercisename, reps, sets,comments };
}

const rows = [
    createData('10/1/2023, 8AM', 'Leg day', 'Squat', 14, 4,'Not-Set'),
    createData('10/1/2023, 10AM', 'Leg day', 'Barbell Squat', 12, 4,'Not-Set'),
    createData('10/1/2023, 1PM', 'Not Set', 'Goblet Squat', 14, 6,'Not-Set'),
    createData('10/1/2023, 4PM', 'Chest day', 'leg press', 12, 4,'Not-Set'),
    createData('10/1/2023, 6PM', 'Leg day', 'Bench press', 15, 3, 'Not-Set'),
];

export default function ExerciseReportTable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date & Time</TableCell>
            <TableCell align="center">Exercise Day</TableCell>
            <TableCell align="center">Exercise date</TableCell>
            <TableCell align="center">Reps</TableCell>
            <TableCell align="center">Sets</TableCell>
            <TableCell align="center">Comments</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.date}
              </TableCell>
              <TableCell align="center">{row.exerciseDay}</TableCell>
              <TableCell align="center">{row.exercisename}</TableCell>
              <TableCell align="center">{row.reps}</TableCell>
              <TableCell align="center">{row.sets}</TableCell>
              <TableCell align="center">{row.comments}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
