import { Typography } from "@mui/material";
import React from "react";

type Props = {
  errormessage?: string;
};

export default function ErrorText(errormessage: Props) {
  const [message, setMessage] = React.useState<string | null>(null);

  React.useEffect(() => {
    setMessage(errormessage!.errormessage!);
  }, [errormessage]);

  return (
    <Typography
      variant="caption"
      display="block"
      gutterBottom
      style={{ color: "#d32f2f" }}
    >
      {message}
    </Typography>
  );
}
