import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { setupInterceptorsTo } from "reduxStore/Authentication/Interceptors";
import { LoginBody, LoginResponse } from "./adminLoginDetailsModel";
import url from "APIConfig";

type InitialState = {
  loading: boolean;
  adminLoginDetailsRes: LoginResponse;
  error: string;
};
const initialState: InitialState = {
  loading: false,
  adminLoginDetailsRes: {} as LoginResponse,
  error: "",
};

// Generates pending, fulfilled and rejected action types
export const fetchAdminLoginDetails = createAsyncThunk<
  LoginResponse,
  LoginBody
>("adminLoginDetail/fetchAdminLoginDetails", async (access: LoginBody) => {
  const headers = {
    Accept: "application/json",
    "Content-type": "application/json",
    Authorization: `Bearer ${access.access}`,
  };
  return await setupInterceptorsTo(axios)
    .get(url.baseUrl + `/adminapp/api/adminActions/adminLoginDetails`, {
      headers,
    })
    .then((response) => response.data);
});

const adminLoginDetailsSlice = createSlice({
  name: "adminLoginDetail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAdminLoginDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchAdminLoginDetails.fulfilled,
      (state, action: PayloadAction<LoginResponse>) => {
        state.loading = false;
        state.adminLoginDetailsRes = action.payload;
        state.error = "";
      }
    );
    builder.addCase(fetchAdminLoginDetails.rejected, (state, action) => {
      state.loading = false;
      state.adminLoginDetailsRes = {} as LoginResponse;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default adminLoginDetailsSlice.reducer;
