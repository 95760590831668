import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from '../../APIConfig'
import { ForgotPasswordResponse } from './Model'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'

type InitialState = {
  loading: boolean
  response: ForgotPasswordResponse
  error: string
}
const initialState: InitialState = {
  loading: false,
  response: {} as ForgotPasswordResponse,
  error: '',
}
const headers = {
  Accept: 'application/json',
  'Content-type': 'application/json',
}

export const forgotPassword = createAsyncThunk<ForgotPasswordResponse, string>(
  'login/forgotPassword',
  async (param: string) => {
    return await setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/api/user/password/reset/${param}/`, { headers })
      .then((response) => response.data)
  },
)

const forgotPasswordSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(forgotPassword.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      forgotPassword.fulfilled,
      (state, action: PayloadAction<ForgotPasswordResponse>) => {
        state.loading = false
        state.response = action.payload
        state.error = ''
      },
    )
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.loading = false
      state.response = {} as ForgotPasswordResponse
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default forgotPasswordSlice.reducer
