import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Drawer,
  Grid,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import FeatureImage from "../images/sportswisdom.png";
import { LogOutResponseBody } from "reduxStore/logOut/Model";
import { logOut } from "reduxStore/logOut/logOutSlice";
import { useAppDispatch } from "reduxStore/hooks";
import PersonIcon from "@mui/icons-material/Person";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DescriptionIcon from "@mui/icons-material/Description";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import HelpIcon from "@mui/icons-material/Help";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import RuleIcon from "@mui/icons-material/Rule";
import QueueIcon from "@mui/icons-material/Queue";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";
import { ColorModeContext } from "../contexts";
import Router from "Routes";

export default function AppFrame() {
  const drawerWidth = 240;
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const token = localStorage.getItem("access");
  const fName = localStorage.getItem("firstname");
  const lName = localStorage.getItem("lastname");
  const fullname = fName + " " + lName;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logoutBody = {
    access: token,
  } as LogOutResponseBody;

  // const dispatch = useDispatch<AppDispatch>();
  // const fetchlist = async () => {
  //     dispatch(fetchSubscription());
  //     await navigate("/subscriptionslist");
  // }

  async function handleLogout() {
    localStorage.removeItem("access");
    await dispatch(logOut(logoutBody));
    await navigate("/login");
    await window.location.reload();
  }

  // ==== List Item====
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const handleListItemClick = (
    _event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  const { mode, setMode } = useContext(ColorModeContext);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        style={{
          height: "calc(100vh - 65px)",
        }}
      >
        <Grid item>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item>
              <List component="nav" aria-label="main mailbox folders">
                <ListItemButton
                  selected={selectedIndex === 0}
                  onClick={(event) => handleListItemClick(event, 0)}
                >
                  <ListItemText>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={0.5}
                      color="text.secondary"
                    >
                      <PersonIcon />
                      <Typography
                        color="text.secondary"
                        component={Link}
                        href="/attendance"
                        style={{
                          textDecoration: "inherit",
                        }}
                      >
                        Attendance
                      </Typography>
                    </Stack>
                  </ListItemText>
                </ListItemButton>

                <ListItemButton
                  selected={selectedIndex === 1}
                  onClick={(event) => handleListItemClick(event, 1)}
                >
                  <ListItemText>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={0.5}
                      color="text.secondary"
                    >
                      <CalendarTodayIcon />
                      <Typography
                        color="text.secondary"
                        component={Link}
                        href="/schedule"
                        style={{
                          textDecoration: "inherit",
                        }}
                      >
                        Schedule
                      </Typography>
                    </Stack>
                  </ListItemText>
                </ListItemButton>

                <ListItemButton
                  selected={selectedIndex === 2}
                  onClick={(event) => handleListItemClick(event, 2)}
                >
                  <ListItemText>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={0.5}
                      color="text.secondary"
                    >
                      <SummarizeIcon />
                      <Typography
                        color="text.secondary"
                        component={Link}
                        href="/exercisereport"
                        style={{
                          textDecoration: "inherit",
                        }}
                      >
                        Monthly Exercise Report
                      </Typography>
                    </Stack>
                  </ListItemText>
                </ListItemButton>

                <ListItemButton
                  selected={selectedIndex === 3}
                  onClick={(event) => handleListItemClick(event, 3)}
                >
                  <ListItemText>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={0.5}
                      color="text.secondary"
                    >
                      <ListAltIcon />
                      <Typography
                        color="text.secondary"
                        component={Link}
                        href="/healthreport"
                        style={{
                          textDecoration: "inherit",
                        }}
                      >
                        Monthly Health Report
                      </Typography>
                    </Stack>
                  </ListItemText>
                </ListItemButton>

                <ListItemButton
                  selected={selectedIndex === 4}
                  onClick={(event) => handleListItemClick(event, 4)}
                >
                  <ListItemText>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={0.5}
                      color="text.secondary"
                    >
                      <DescriptionIcon />
                      <Typography
                        color="text.secondary"
                        component={Link}
                        href="/healthvitals"
                        style={{
                          textDecoration: "inherit",
                        }}
                      >
                        My Health Vitals
                      </Typography>
                    </Stack>
                  </ListItemText>
                </ListItemButton>

                <ListItemButton
                  selected={selectedIndex === 5}
                  onClick={(event) => handleListItemClick(event, 5)}
                >
                  <ListItemText>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={0.5}
                      color="text.secondary"
                    >
                      <QueueIcon />
                      <Typography
                        color="text.secondary"
                        component={Link}
                        href="/subscriptions"
                        style={{
                          textDecoration: "inherit",
                        }}
                      >
                        Subscriptions
                      </Typography>
                    </Stack>
                  </ListItemText>
                </ListItemButton>

                <ListItemButton
                  selected={selectedIndex === 6}
                  onClick={(event) => handleListItemClick(event, 6)}
                >
                  <ListItemText>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={0.5}
                      color="text.secondary"
                    >
                      <SearchIcon />
                      <Typography
                        color="text.secondary"
                        component={Link}
                        href="/findorganization"
                        style={{
                          textDecoration: "inherit",
                        }}
                      >
                        Find Gym
                      </Typography>
                    </Stack>
                  </ListItemText>
                </ListItemButton>

                <ListItemButton
                  selected={selectedIndex === 7}
                  onClick={(event) => handleListItemClick(event, 7)}
                >
                  <ListItemText>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={0.5}
                      color="text.secondary"
                    >
                      <NotificationsNoneIcon />
                      <Typography
                        color="text.secondary"
                        component={Link}
                        href="/notification"
                        style={{
                          textDecoration: "inherit",
                        }}
                      >
                        Notification
                      </Typography>
                    </Stack>
                  </ListItemText>
                </ListItemButton>

                <ListItemButton
                  selected={selectedIndex === 8}
                  onClick={(event) => handleListItemClick(event, 8)}
                >
                  <ListItemText>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={0.5}
                      color="text.secondary"
                    >
                      <FeaturedPlayListIcon />
                      <Typography
                        color="text.secondary"
                        component={Link}
                        href="/subscriptionslist"
                        style={{
                          textDecoration: "inherit",
                        }}
                      >
                        Subscription List
                      </Typography>
                    </Stack>
                  </ListItemText>
                </ListItemButton>
              </List>
            </Grid>
          </Grid>
        </Grid>

        <Grid item style={{ backgroundColor: "#FEBC11" }}>
          <List>
            <ListItemButton
              selected={selectedIndex === 9}
              onClick={(event) => handleListItemClick(event, 9)}
            >
              <ListItemText>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={0.5}
                >
                  <RuleIcon />
                  <Typography
                    color="text.primary"
                    component={Link}
                    href="/rule"
                    style={{
                      textDecoration: "inherit",
                    }}
                  >
                    Rules
                  </Typography>
                </Stack>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 10}
              onClick={(event) => handleListItemClick(event, 10)}
            >
              <ListItemText>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={0.5}
                >
                  <HelpIcon />
                  <Typography
                    color="text.primary"
                    component={Link}
                    href="/needhelp"
                    style={{
                      textDecoration: "inherit",
                    }}
                  >
                    Need Help
                  </Typography>
                </Stack>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 11}
              onClick={(event) => handleListItemClick(event, 11)}
            >
              <ListItemText>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={0.5}
                >
                  <AccountCircleIcon />
                  <Typography
                    color="text.primary"
                    component={Link}
                    href="/userprofile"
                    style={{
                      textDecoration: "inherit",
                    }}
                  >
                    User Profile
                  </Typography>
                </Stack>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 12}
              onClick={() => handleLogout()}
            >
              <ListItemText>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={0.5}
                >
                  <LogoutIcon />
                  <Typography
                    color="text.primary-"
                    style={{
                      textDecoration: "inherit",
                    }}
                  >
                    Log Out
                  </Typography>
                </Stack>
              </ListItemText>
            </ListItemButton>
          </List>
        </Grid>
      </Grid>
    </Box>
  );

  const layout = (
    <>
      <Box sx={{ display: "flex" }}>
        {token ? (
          <AppBar
            position="fixed"
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <Toolbar>
              <Grid container spacing={2} justifyContent="space-between" px={1}>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={7}
                  >
                    <Grid item>
                      <Stack direction="row" spacing={1}>
                        <img src={FeatureImage} width="40" height="40" alt="" />
                        <Typography
                          variant="h6"
                          component={Link}
                          href="/dashboard"
                          color="text.secondary"
                          sx={{ fontSize: 20 }}
                        >
                          sportsWISDOM
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>

                {token ? (
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-start"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography
                          sx={{
                            display: {
                              paddingTop: "6px",
                            },
                          }}
                        ></Typography>
                      </Grid>
                      <>
                        <Typography
                          sx={{
                            display: {
                              paddingTop: "8px",
                            },
                          }}
                        >
                          <IconButton onClick={setMode}>
                            {mode === "light" ? (
                              <DarkModeOutlined />
                            ) : (
                              <LightModeOutlined />
                            )}
                          </IconButton>
                        </Typography>
                      </>
                      <Grid item>
                        <Typography
                          sx={{
                            display: {
                              paddingTop: "8px",
                            },
                          }}
                        >
                          <PersonIcon />
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{
                            display: {
                              color: "text.primary",
                              fontSize: "14px",
                              paddingTop: "8px",
                            },
                          }}
                        >
                          {fullname}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item>
                    <Link
                      href="/login"
                      underline="always"
                      sx={{
                        display: {
                          sm: "none",
                          md: "inherit",
                          color: "#FFFFFF",
                        },
                      }}
                    >
                      {""}
                    </Link>
                  </Grid>
                )}
              </Grid>
            </Toolbar>
          </AppBar>
        ) : null}
        <Box component="nav">
          {token ? (
            <div>
              <Drawer
                open={mobileOpen}
                onClose={handleDrawerToggle}
                variant="permanent"
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,
                  [`& .MuiDrawer-paper`]: {
                    width: drawerWidth,
                    boxSizing: "border-box",
                    backgroundColor: "backgroundColor.paper",
                  },
                }}
              >
                <Toolbar />
                {drawer}
              </Drawer>
            </div>
          ) : null}
        </Box>
        <Box component="main" sx={{ flexGrow: 1 }}>
          {token ? <Toolbar /> : null}
          <Router />
        </Box>
      </Box>
    </>
  );

  React.useEffect(() => {
    if (selectedIndex === 0) {
      navigate("/attendance");
    } else if (selectedIndex === 1) {
      navigate("/schedule");
    } else if (selectedIndex === 2) {
      navigate("/exercisereport");
    } else if (selectedIndex === 3) {
      navigate("/healthreport");
    } else if (selectedIndex === 4) {
      navigate("/healthvitals");
    } else if (selectedIndex === 5) {
      navigate("/subscriptions");
    } else if (selectedIndex === 6) {
      navigate("/findorganization");
    } else if (selectedIndex === 7) {
      navigate("/notification");
    } else if (selectedIndex === 8) {
      navigate("/subscriptionslist");
    } else if (selectedIndex === 9) {
      navigate("/rule");
    } else if (selectedIndex === 10) {
      navigate("/needhelp");
    } else if (selectedIndex === 11) {
      navigate("/userprofile");
    } else if (selectedIndex === 12) {
      handleLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);
  return <div>{layout}</div>;
}
