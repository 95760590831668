import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";

const tiers = [
  {
    title: "Students Offer",
    subheader:
      'Be Our "Student Brand Ambassador" & Get 1-month free Gym membership on 1-month subscription.',
    price: "",
    description: [
      "● You need to be an active student of an accredited college or university and aged 18+",
      "● Share copy of your Student ID card",
      "● Share a selfie in front of your Institute",
      "● Share your Gym experience publicly with your social media users along with your Gym picture.",
    ],
  },
  {
    title: "Couple Offer",
    subheader:
      "Be our Fit Couple Ambassador and get 1 month Membership on 3 month subscription",
    price: "",
    description: [
      "● Share a Copy of your togetherness",
      "● Both need to select the same time slot so you can be together on the fitness journey.",
      '●  We like you to be our "Fit Couple Ambassador" also. You need to share your JD Fitness Center experience publicly with your social media users along with your Gymming picture.',
    ],
  },
  {
    title: "Family Offer",
    subheader:
      "Join as a Family, and get 1-month subscription on 3-month subscription for all.",
    price: "",
    description: [
      "● At least 3 people from your family need to subscribe",
      "● Submit a copy of your Family members",
      "● Get the whole family involved in regular physical activity & reach Fitness Goals easily",
    ],
  },
  {
    title: "Trial Offer",
    subheader: "Confused or not sure of your Availability?",
    price: "",
    description: [
      "● Choose Trial Offer from JD Fitness Get 12 Classes at 999 Rs with Validity of 3 months.",
      "● Take classes at your own speed No commitment, just enjoy the process of finding a Healthier YOU!",
    ],
  },
  {
    title: "Society Offer",
    subheader: 'Be our "Local/ Society Brand Ambassador."',
    price: "",
    description: [
      "For that, we like you to offer the Birati Delux Complex Society Members 1- month free Gym subscription when you subscribe for 1 month.",
    ],
  },
  {
    title: "Single Day Offer",
    subheader: "",
    price: "",
    description: [""],
  },
];

export default function SpecialOffers() {
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ pt: 2, pb: 2 }}
      >
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="text.secondary"
          gutterBottom
        >
          Special Offers
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary">
          Going to the gym and exercising has numerous health benefits such as
          weight loss, toning the body, increasing flexibility, improving
          cardiac health, joint health, strength and stamina.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === "Enterprise" ? 12 : 6}
              md={4}
              style={{ display: "flex" }}
            >
              <Card sx={{ border: 2, borderColor: "#FEBC11", borderRadius: 2 }}>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  action={tier.title === "Pro" ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: "center",
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      mb: 2,
                    }}
                  >
                    <Typography
                      component="h2"
                      variant="h3"
                      color="text.primary"
                    >
                      {tier.price}
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {" "}
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      ></Container>
    </React.Fragment>
  );
}
