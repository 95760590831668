import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import url from 'APIConfig'
import { CareGiverDefaultResponse } from './preferenceModel'

type InitialState = {
  loading: boolean
  preferences: CareGiverDefaultResponse
  error: string
}
const initialState: InitialState = {
  loading: false,
  preferences: {} as CareGiverDefaultResponse,
  error: '',
}


// Generates pending, fulfilled and rejected action types
export const fetchPreference = createAsyncThunk<CareGiverDefaultResponse, string>(
  'preferences/fetchPreference',
  async (access: string) => {
    const headers = {
        Accept : 'application/json',
        'Content-type' : 'application/json',
        Authorization: `Bearer ${access}`,
      }
    return await setupInterceptorsTo(axios)
    .get(url.baseUrl + `/caregiverapp/api/defaultPref/getPreferences`, { headers })
    .then((response) => response.data)
  },
)

const preferenceSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPreference.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchPreference.fulfilled, (state, action: PayloadAction<CareGiverDefaultResponse>) => {
      state.loading = false
      state.preferences = action.payload
      state.error = ''
    })
    builder.addCase(fetchPreference.rejected, (state, action) => {
      state.loading = false
      state.preferences = {} as CareGiverDefaultResponse
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default preferenceSlice.reducer
