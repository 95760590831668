import { Box, Grid } from "@mui/material";
import React from "react";
import { OtpGenerateBody } from "reduxStore/otpGeneration/Model";
import { Otp } from "reduxStore/otpGeneration/otpGenerateSlice";
import OtpGeneration from "pages/OnBoarding/component/SigninOtp/component/OtpGeneration"
import SigninwithOtp from "pages/OnBoarding/component/SigninOtp/component/SigninwithOtp";
import { useDispatch } from "react-redux";
import { AppDispatch } from "reduxStore/store";
import { useAppSelector } from "reduxStore/hooks";
import { OtpLoginBody } from "reduxStore/otpLogin/Model";
import { OtpLogin } from "reduxStore/otpLogin/otpLoginSlice";
import { useNavigate } from "react-router-dom";


interface EmailOrPhValue {
  email: string;
}

export default function SignIn() {
  const dispatch = useDispatch<AppDispatch>();
  let navigate = useNavigate();
  const OtpGenerateValue = useAppSelector((state) => state.otpGenerate);
  const OtpLoginValue = useAppSelector((state) => state.otplogin);

  const [emailorph, setEmailOrPh] = React.useState("");
  const [bannerState, setBannerState] = React.useState(false);

  const updateDataStateFromChild = async (passedval: EmailOrPhValue) => {
    setEmailOrPh(passedval.email);
    await dispatch(Otp(passedval as OtpGenerateBody));
    setBannerState(true);
  };

  const updateDataStateFromSigninwithOtp = async (signinval: {}) => {
    await dispatch(OtpLogin(signinval as OtpLoginBody));
    await navigate("/dashboard");
  };

  React.useEffect(() => {
    console.log("APIRESPONSE", OtpLoginValue);
  }, [OtpLoginValue]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box sx={{ my: 3, mx: 4 }}>
        <OtpGeneration
          updatedDataState={updateDataStateFromChild}
          loadingState={OtpGenerateValue.loading}
          bannerState={bannerState}
          responseStatus={OtpGenerateValue.response.status}
          responseMsg={OtpGenerateValue.response.message}
          responseErrorMsg={OtpGenerateValue.error}
        />
        <SigninwithOtp
          emailorph={emailorph}
          loadingState={OtpLoginValue.loading}
          updatedDataStateFromOtp={updateDataStateFromSigninwithOtp}
          responseStatus={OtpGenerateValue.response.status}
          responseErrorMsg={OtpLoginValue.error}
        />
      </Box>
    </Grid>
  );
}
