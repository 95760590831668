import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from '../../APIConfig'
import { OtpLoginBody, OtpLoginResponse } from './Model'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'

type InitialState = {
  loading: boolean
  response: OtpLoginResponse
  error: string
}
const initialState: InitialState = {
  loading: false,
  response: {} as OtpLoginResponse,
  error: '',
}
const headers = {
  Accept: 'application/json',
  'Content-type': 'application/json',
}

export const OtpLogin = createAsyncThunk<OtpLoginResponse, OtpLoginBody>(
  'login/OtpLogin',
  async (payload: OtpLoginBody, { rejectWithValue }) => {
    return await setupInterceptorsTo(axios)
      .post(Url.baseUrl + '/patientapp/email-otp-auth/', payload, { headers })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data.message))
  },
)

const otpLoginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(OtpLogin.pending, (state) => {
      state.loading = true
    })
    builder.addCase(OtpLogin.fulfilled, (state, action: PayloadAction<OtpLoginResponse>) => {
      state.loading = false
      state.response = action.payload
      localStorage.setItem('access', action.payload.access)
      localStorage.setItem('id', String(action.payload.user.id))
      localStorage.setItem('username', action.payload.user.username)
      localStorage.setItem('email', action.payload.user.email)
      localStorage.setItem('firstname', action.payload.user.first_name)
      localStorage.setItem('lastname', action.payload.user.last_name)
      state.error = ''
    })
    builder.addCase(OtpLogin.rejected, (state, action) => {
      state.loading = false
      state.response = {} as OtpLoginResponse
      state.error = action.payload! as string
    })
  },
})

export default otpLoginSlice.reducer
