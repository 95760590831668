/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react";
import OtpGeneration from "pages/OnBoarding/component/SigninOtp/component/OtpGeneration";
import { useAppSelector } from "reduxStore/hooks";
import { OtpGenerateBody } from "reduxStore/otpGeneration/Model";
import { Otp } from "reduxStore/otpGeneration/otpGenerateSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "reduxStore/store";
import Signup from "pages/OnBoarding/component/SignUp/component/Signup";
import {
  Avatar,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import LoginImage from "images/sw-jd.png";
import icon from "images/sportswisdom.png";

interface EmailOrPhValue {
  email: string;
}

export default function index() {
  const dispatch = useDispatch<AppDispatch>();
  const OtpGenerateValue = useAppSelector((state) => state.otpGenerate);

  const [emailorph, setEmailOrPh] = React.useState("");
  const [bannerState, setBannerState] = React.useState(false);
  const [formState, setFormState] = React.useState(true);

  const updateDataStateFromChild = async (passedval: EmailOrPhValue) => {
    setEmailOrPh(passedval.email);
    await dispatch(Otp(passedval as OtpGenerateBody));
    setBannerState(true);
    setFormState(false);
  };

  React.useEffect(() => {}, []);

  return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        wrap="wrap-reverse"
      >
        <Grid item xs={12} sm={12} md={6} lg={6}
        display={{ xs: "none", lg: "block" }}>
          <Avatar
            variant={"square"}
            alt="The image"
            src={LoginImage}
            style={{
              width: "100%",
              height: "100vh",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            p={2}
          >
            <Grid item>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ border: 1, borderColor: "borderColor", borderRadius: 2 }}
              >
                <Grid item pt={2}>
                  <img alt="icon" src={icon} width="60" height="60" />
                </Grid>
                <Grid item>
                  <Typography component="h1" variant="h5">
                    Sign Up
                  </Typography>
                </Grid>
                <Grid item pt={2}>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    sportsWISDOM
                  </Typography>
                </Grid>
                <Box sx={{ marginTop: 2 }}>
                  <OtpGeneration
                    updatedDataState={updateDataStateFromChild}
                    loadingState={OtpGenerateValue.loading}
                    bannerState={bannerState}
                    responseStatus={OtpGenerateValue.response.status}
                    responseMsg={OtpGenerateValue.response.message}
                    responseErrorMsg={OtpGenerateValue.error}
                  />
                </Box>
                <Signup emailorph={emailorph} formState={formState} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  );
}
