import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "reduxStore/hooks";
import { useParams } from "react-router-dom";
import {
  SubscriptionStatusBody,
  SubscriptionStatusById,
} from "reduxStore/subscriptionStatus/subscriptionStatusModel";
import { subscriptionStatusById } from "reduxStore/subscriptionStatus/subscriptionStatusSlice";
import dayjs from "dayjs";
export default function SubscriberStatus() {
  const dispatch = useAppDispatch();
  const { patientId } = useParams();
  const Bearer = localStorage.getItem("access");
  const [subscriberId, setSubscriberId] = useState(0);

  React.useEffect(() => {
    if (patientId !== undefined) {
      setSubscriberId(Number(patientId));
    }
  }, [patientId]);

  const subscriptionStatusBody = {
    patientId: subscriberId,
  } as SubscriptionStatusBody;

  const subscriptionStatusByIdBody = {
    body: subscriptionStatusBody,
    access: Bearer!,
  } as SubscriptionStatusById;

  useEffect(() => {
    if (subscriberId !== 0) {
      dispatch(subscriptionStatusById(subscriptionStatusByIdBody));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, subscriberId]);

  const subscriptionStatusRes = useAppSelector(
    (state) => state.subscriptionStatus
  );
  useEffect(() => {
    console.log("subscriptionStatusRes", subscriptionStatusRes);
  }, [subscriptionStatusRes]);

  useEffect(() => {}, [subscriptionStatusRes]);

  function capitalizeFirstLetter(string: any) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

  return (
    <Container maxWidth="xl">
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        <Card style={{ borderRadius: 20 }}>
          <CardHeader
            title={
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Subscription Details
                  </Typography>
                </Grid>
              </Grid>
            }
          />
          <CardContent>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sm={6} md={8} lg={8}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  spacing={2}
                >
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Typography>
                      Subscription Status:{" "}
                      {subscriptionStatusRes?.response?.subscriptionStatus !== undefined
                        ? capitalizeFirstLetter(subscriptionStatusRes?.response?.subscriptionStatus)
                        : "N/A"}
                    </Typography>
                    <Typography>
                      Subscription Scheme:{" "}
                      {subscriptionStatusRes?.response?.subscriptionType !==
                      undefined
                        ? capitalizeFirstLetter(subscriptionStatusRes?.response?.subscriptionType)
                        : "N/A"}
                    </Typography>
                    <Typography>
                      Start Date:{" "}
                      {subscriptionStatusRes?.response
                        ?.subscriptionStartDate !== undefined
                        ? dayjs(
                            subscriptionStatusRes?.response
                              ?.subscriptionStartDate
                          ).format("DD/MM/YYYY")
                        : "N/A"}
                    </Typography>
                    <Typography>
                      End Date:{" "}
                      {subscriptionStatusRes?.response?.subscriptionEndDate !==
                      undefined
                        ? dayjs(
                            subscriptionStatusRes?.response?.subscriptionEndDate
                          ).format("DD/MM/YYYY")
                        : "N/A"}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              spacing={1}
            >
              <Grid item>
                <Button
                  variant="text"
                  // endIcon={<ArrowRightAltIcon />}
                  sx={{ textTransform: "none" }}
                  // onClick={() => handleDemographicToggle()}
                >
                  View All
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Stack>
    </Container>
  );
}
