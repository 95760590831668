import * as React from "react";
import Grid from "@mui/material/Grid";
import {Pagination, Typography } from "@mui/material";
import ExerciseReportTable from "./ExerciseReportTable";
export default function ExerciseReport() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      px={2}
      py={2}
      spacing={2}
    >
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Monthly Exercise Reports
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <ExerciseReportTable/>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Pagination count={10} />
        </Grid>
      </Grid>
    </Grid>
  );
}
