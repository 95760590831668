import axios from "axios";
import {
  SubscriberDetailsResponse,
  ViewSubscriberDetailsById,
} from "./subscriberDetailsModel";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setupInterceptorsTo } from "reduxStore/Authentication/Interceptors";
import url from "APIConfig";

type InitialState = {
  loading: boolean;
  response: SubscriberDetailsResponse;
  error: string;
};
const initialState: InitialState = {
  loading: false,
  response: {} as SubscriberDetailsResponse,
  error: "",
};

export const viewSubscriberDetailsById = createAsyncThunk<
  SubscriberDetailsResponse,
  ViewSubscriberDetailsById
>(
  "healthRecordId/viewSubscriberDetailsById",
  async (payload: ViewSubscriberDetailsById, { rejectWithValue }) => {
    const headers = {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${payload.access}`,
    };
    return await setupInterceptorsTo(axios)
      .post(
        url.baseUrl +
          `/patientapp/api/patient/get_patient_demographics_details`,
        payload.body,
        { headers }
      )
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(error.response.data.message || "Something went wrong")
      );
  }
);

const viewSubscriberDetailsByIdSlice = createSlice({
  name: "healthRecordId",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(viewSubscriberDetailsById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      viewSubscriberDetailsById.fulfilled,
      (state, action: PayloadAction<SubscriberDetailsResponse>) => {
        state.loading = false;
        state.response = action.payload;
        state.error = "";
      }
    );
    builder.addCase(viewSubscriberDetailsById.rejected, (state, action) => {
      state.loading = false;
      state.response = {} as SubscriberDetailsResponse;
      state.error = action.error.message || "Data not fetched";
    });
  },
});

export default viewSubscriberDetailsByIdSlice.reducer;
