import * as React from "react";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { useAppSelector } from "reduxStore/hooks";
import { SignupBody } from "reduxStore/signup/Model";
import { Signup } from "reduxStore/signup/signupSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "reduxStore/store";
import ResponseBanner from "components/ResponseBanner";
import ErrorText from "components/ErrorText";

interface SignUpForm {
  otp?: string;
  first_name?: string;
  last_name?: string;
  password?: string;
  showPassword?: boolean;
}

type Props = {
  emailorph: string;
  formState: boolean;
};

export default function SignUp({ emailorph, formState }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  let navigate = useNavigate();
  const signUpDetails = useAppSelector((state) => state.signup);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpForm>();

  const onSubmit = async (data: SignUpForm) => {
    let mainbody = Object.assign(data, { email: emailorph });
    await dispatch(Signup(mainbody as SignupBody));
    await navigate("/login");
  };

  const [otp, setOtpValue] = React.useState<SignUpForm>({
    otp: "",
  });

  const handlesetOtpChange =
    (prop: keyof SignUpForm) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setOtpValue({ ...otp, [prop]: event.target.value });
    };

  const [first_name, setFirstName] = React.useState<SignUpForm>({
    first_name: "",
  });

  const handleFirstNameChange =
    (prop: keyof SignUpForm) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFirstName({ ...first_name, [prop]: event.target.value });
    };

  const [last_name, setLastName] = React.useState<SignUpForm>({
    last_name: "",
  });
  const handleLastNameChange =
    (prop: keyof SignUpForm) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLastName({ ...last_name, [prop]: event.target.value });
    };

  interface State {
    password: string;
    showPassword: boolean;
  }

  const [password, setPassword] = React.useState<State>({
    password: "",
    showPassword: false,
  });

  const handlePasswordChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword({ ...password, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  React.useEffect(() => {}, [formState]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ my: 3, mx: 4 }}>
        <Grid item>
          <FormControl variant="outlined" fullWidth disabled={formState}>
            <InputLabel htmlFor="outlined-adornment-fullname">OTP</InputLabel>
            <OutlinedInput
              id="outlined-adornment-fullname"
              label="OTP"
              value={otp.otp}
              {...register("otp", {
                required: true,
              })}
              onChange={handlesetOtpChange("otp")}
              fullWidth
            />
          </FormControl>
          {errors?.otp?.type === "required" && (
            <ErrorText errormessage={"OTP is required"} />
          )}
        </Grid>
        <Grid item sx={{ mt: 2 }}>
          <FormControl variant="outlined" fullWidth disabled={formState}>
            <InputLabel htmlFor="outlined-adornment-firstname">
              First Name
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-firstname"
              label="First Name"
              value={first_name.first_name}
              {...register("first_name", {
                required: true,
              })}
              onChange={handleFirstNameChange("first_name")}
              fullWidth
            />
          </FormControl>
          {errors?.first_name?.type === "required" && (
            <ErrorText errormessage={"First Name is required"} />
          )}
        </Grid>
        <Grid item sx={{ mt: 2 }}>
          <FormControl variant="outlined" fullWidth disabled={formState}>
            <InputLabel htmlFor="outlined-adornment-lastname">
              Last Name
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-lastname"
              label="Last Name"
              value={last_name.last_name}
              {...register("last_name", {
                required: true,
              })}
              onChange={handleLastNameChange("last_name")}
              fullWidth
            />
          </FormControl>
          {errors?.last_name?.type === "required" && (
            <ErrorText errormessage={"Last Name is required"} />
          )}
        </Grid>
        <Grid item sx={{ mt: 2 }}>
          <FormControl variant="outlined" fullWidth disabled={formState}>
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              fullWidth
              label="Password"
              type={password.showPassword ? "text" : "password"}
              value={password.password}
              onChange={handlePasswordChange("password")}
              endAdornment={
                <InputAdornment position="end" disablePointerEvents={formState}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {password.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={formState}
          >
            {signUpDetails.loading === true ? (
              <Stack direction="row" spacing={1}>
                <Typography>Sign Up</Typography>
                <Box pl={1}>
                  <CircularProgress color="inherit" size={18} />
                </Box>
              </Stack>
            ) : (
              <Typography>Sign Up</Typography>
            )}
          </Button>
        </Grid>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Typography>
              Already have an account? {""}
              <Link href="/login" color="text.secondary" underline="always">
                Sign in
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <ResponseBanner
        responseMsg={signUpDetails.response.message}
        responseErrorMsg={signUpDetails.error}
      />
    </form>
  );
}
