import { Alert, Box, Collapse } from "@mui/material";
import React from "react";

type Props = {
  bannerState?: boolean;
  responseStatus?: number;
  responseMsg?: string;
  responseErrorMsg: string;
};

export default function ResponseBanner({
  bannerState,
  responseStatus,
  responseMsg,
  responseErrorMsg,
}: Props) {
  const [status, setStatus] = React.useState<number | null>(null);
  const [message, setMessage] = React.useState<string | null>(null);
  const [errormessage, setErrorMessage] = React.useState<string | null>(null);
  const [openalert, setOpenBanner] = React.useState(false);
  const closeAlert = () => {
    setOpenBanner(false);
  };

  React.useEffect(() => {
    setOpenBanner(bannerState!);
    setStatus(responseStatus!);
    setMessage(responseMsg!);
    setErrorMessage(responseErrorMsg!);
  }, [bannerState, responseStatus, responseMsg, responseErrorMsg]);

  return (
    <Box>
      {status === 1 ? (
        <Collapse in={openalert}>
          <Alert severity="success" onClose={closeAlert}>
            <Box sx={{ mx: "auto", width: 180 }}>{message}</Box>
          </Alert>
        </Collapse>
      ) : (
        <Collapse in={openalert}>
          <Alert severity="error" onClose={closeAlert}>
            <Box sx={{ mx: "auto", width: 180 }}>{errormessage}</Box>
          </Alert>
        </Collapse>
      )}
    </Box>
  );
}
