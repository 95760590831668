import * as React from "react";
import Grid from "@mui/material/Grid";
import { Pagination, Typography } from "@mui/material";
import HealthReportTable from "./HealthReportTable";
export default function HealthReport() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      px={2}
      py={2}
      spacing={2}
    >
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Monthly Health Reports
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <Grid item>
            <Button
              variant="contained"
              sx={{
                fontWeight: 'bold',
                mt: 3,
                background: "#FEBC11",
                color: "#000000",
                "&:hover": {
                  backgroundColor: "#FEBC11",
                  color: "#000000",
                },
              }}
            >
              Add Health Reports
            </Button>
          </Grid>
        </Grid>
      </Grid> */}
      <Grid item>
        <HealthReportTable/>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Pagination count={10} />
        </Grid>
      </Grid>
    </Grid>
  );
}
