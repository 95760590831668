import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#FEBC11",
    },
    secondary: {
      main: "#757575",
    },
    success: {
      main: "#2e7d32",
    },
    info: {
      main: "#085909",
    },
    warning: {
      main: "#ed6c02",
    },
    error: {
      main: "#d32f2f",
    },
    text: {
      primary: "#000000",
      secondary: "#000000",
      disabled: "#616161",
    },
    background: {
      default: "#ffffff",
      paper: "#FFF8E5",
    },
    borderColor: "#FFF8E5",
    action: {
      active: "#000000",
      hover: "#ffe082",
      hoverOpacity: "50%",
      selected: "#ffe082",
      selectedOpacity: "100%",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"San Francisco"',
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: 16,
    color: "#000000",
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: "#FBEAE5",
          borderRadius: "8px",
          borderColor: "#000000",
          minHeight: "40px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          alignItems: "start",
          justifyContent: "start",
          textAlign: "left",
          textTransform: "none",
          minHeight: 0,
          padding: "10px 20px",
        },
      },
    },
  },
});

export default lightTheme;
