import axios from "axios";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setupInterceptorsTo } from "reduxStore/Authentication/Interceptors";
import url from "APIConfig";
import {
  SubscriptionStatusById,
  SubscriptionStatusResponse,
} from "./subscriptionStatusModel";

type InitialState = {
  loading: boolean;
  response: SubscriptionStatusResponse;
  error: string;
};
const initialState: InitialState = {
  loading: false,
  response: {} as SubscriptionStatusResponse,
  error: "",
};

export const subscriptionStatusById = createAsyncThunk<
  SubscriptionStatusResponse,
  SubscriptionStatusById
>(
  "patientId/subscriptionStatusById",
  async (payload: SubscriptionStatusById, { rejectWithValue }) => {
    const headers = {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${payload.access}`,
    };
    return await setupInterceptorsTo(axios)
      .post(
        url.baseUrl +
          `/subscriptionapp/api/subscriptionStatus/getSubscriptionStatus`,
        payload.body,
        { headers }
      )
      .then((response) => response.data)
      .catch((error) => {
        rejectWithValue(
          error?.response?.data !== undefined
            ? error?.response?.data?.message !== undefined
              ? error?.response?.data?.message
              : "Something went wrong"
            : "Something went wrong"
        );
      });
  }
);

const subscriptionStatusByIdSlice = createSlice({
  name: "patientId",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(subscriptionStatusById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      subscriptionStatusById.fulfilled,
      (state, action: PayloadAction<SubscriptionStatusResponse>) => {
        state.loading = false;
        state.response = action.payload;
        state.error = "";
      }
    );
    builder.addCase(subscriptionStatusById.rejected, (state, action) => {
      state.loading = false;
      state.response = {} as SubscriptionStatusResponse;
      state.error = "Something went wrong";
      console.log("state.error",state.error);
    });
  },
});

export default subscriptionStatusByIdSlice.reducer;
