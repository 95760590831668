import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "reduxStore/hooks";
import dayjs from "dayjs";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import MWPageTitle from "components/MWPageTitle";
import { fetchPreference } from "reduxStore/preference/preferenceSlice";
import {
  SearchBody,
  SearchSubscriptionByOrgIdBody,
} from "reduxStore/subscriberList/subscriptionListModel";
import { searchSubscriptionByOrgId } from "reduxStore/subscriberList/subscriptionListSlice";
import MWExceptionList from "components/MWExceptionList";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function SubscriptionList() {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const Bearer = localStorage.getItem("access");
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [organizationId, setOrganizationId] = useState("");
  const [listCount, setListCount] = useState("");
  const [searchPatient, setSearchPatient] = useState("");
  React.useEffect(() => {
    dispatch(fetchPreference(localStorage.getItem("access")!));
  }, [dispatch]);
  const preferenceRes = useAppSelector((state) => state.preferenceDetails);
  React.useEffect(() => {
    if (preferenceRes?.preferences?.CareGiverDefaultPreferences !== undefined) {
      setOrganizationId(
        String(
          preferenceRes?.preferences?.CareGiverDefaultPreferences
            ?.organizationId
        )
      );
    }
  }, [preferenceRes]);

  const searchBody = {
    searchKey: searchPatient,
    organizationId: organizationId,
  } as SearchBody;

  const searchPatientBody = {
    body: searchBody,
    access: Bearer!,
  } as SearchSubscriptionByOrgIdBody;

  const searchResultRes = useAppSelector((state) => state.subscriptionList);
  const searchPatientByOrganizationValue =
    searchResultRes?.response?.patientList;
  useEffect(() => {
    if (searchResultRes?.response?.count !== undefined) {
      setListCount(String(searchResultRes?.response?.count));
    }
    if (searchResultRes?.response?.patientList !== undefined) {
      let dataPointArr = searchResultRes?.response?.patientList.map(
        (element: any) => ({
          id: element.patientId,
          firstname: element.givenName,
          lastname: element.familyName,
          userName: element.userName,
          patientId: element.patientId,
          dob: dayjs(element.dob).format("MMMM D, YYYY"),
          phone: element.phone,
          pincode: element.pincode,
          createdDate: element.createDate,
          modifiedDate: element.modifiedDate,
        })
      );
      setDataGridArray(dataPointArr);
    }
  }, [searchResultRes]);
  const columns: GridColDef[] = [
    {
      field: "patientId",
      headerName: "View",
      width: 100,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoDetails(params.value);
          }}
        >
          View
        </Button>
      ),
    },
    { field: "id", headerName: "Patient Id", width: 90 },
    {
      field: "firstname",
      headerName: "First Name",
      flex: 1,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      width: 130,
      flex: 1,
    },
    {
      field: "dob",
      headerName: "Date of Birth",
      width: 200,
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      width: 200,
      flex: 1,
    },
  ];

  const gotoDetails = (id: any) => {
    console.log("patientId", id);
    navigate(`/subscriberdetails/${id}`);
  };
  const gymSubscriptionList = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Box>
                    <TextField
                      label="Search Subscriber in this Gym"
                      size="small"
                      value={searchPatient}
                      onKeyDown={(ev) => {
                        if (ev.key === "Enter") {
                          dispatch(
                            searchSubscriptionByOrgId(searchPatientBody)
                          );
                          ev.preventDefault();
                        }
                      }}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setSearchPatient(event.target.value);
                      }}
                      sx={{ width: 320 }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Box>
          {searchPatientByOrganizationValue &&
          searchPatientByOrganizationValue.length ? (
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              disableSelectionOnClick
              hideFooter
              hideFooterPagination
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Box>
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          py={2}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item>
              <MWPageTitle
                title="JD Fitness Subscriber List"
                enableCount={true}
                count={listCount}
              />
            </Grid>
          </Grid>
          {gymSubscriptionList}
        </Stack>
      </Container>
    </Box>
  );
}
