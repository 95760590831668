import { configureStore } from '@reduxjs/toolkit'
import forgotPasswordSlice from './forgotPassword/forgotPasswordSlice'
import loginReducer from './login/loginSlice'
import logOutSlice from './logOut/logOutSlice'
import signupSlice from './signup/signupSlice'
import otpGenerateSlice from './otpGeneration/otpGenerateSlice'
import otpLoginSlice from './otpLogin/otpLoginSlice'
import searchSubscriptionByOrgIdSlice from './subscriberList/subscriptionListSlice'
import viewSubscriberDetailsByIdSlice from './subscriberDetails/subscriberDetailsSlice'
import preferenceSlice from './preference/preferenceSlice'
import subscriptionStatusByIdSlice from './subscriptionStatus/subscriptionStatusSlice'
import adminLoginDetailsSlice from './adminLoginDetails/adminLoginDetailsSlice'
const store = configureStore({
  reducer: {
    otpGenerate: otpGenerateSlice,
    login: loginReducer,
    loginDetailsValue: adminLoginDetailsSlice,
    logout: logOutSlice,
    forgotPassword: forgotPasswordSlice,
    signup: signupSlice,
    otplogin: otpLoginSlice,
    preferenceDetails: preferenceSlice,
    subscriptionList: searchSubscriptionByOrgIdSlice,
    subscriberDetails: viewSubscriberDetailsByIdSlice,
    subscriptionStatus: subscriptionStatusByIdSlice,
  },
})

export default store
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, alerts: AlertsState}
export type AppDispatch = typeof store.dispatch
