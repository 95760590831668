import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(
  date: string,
  bp: string,
  temp: number,
  spo2: number,
  weight: number,
  height: number,
  ecg: string
) {
  return { date, bp, temp, spo2, weight,height,ecg };
}

const rows = [
    createData('10/1/2023, 8AM', '122/84', 97, 100, 52,1.72,'Normal Sinus Rhythm'),
    createData('10/1/2023, 10AM', '120/74', 98, 99, 79,1.79,'Normal Sinus Rhythm'),
    createData('10/1/2023, 1PM', '121/81', 98.7, 98, 82,1.82,'Normal Sinus Rhythm'),
    createData('10/1/2023, 4PM', '122/84', 97.7, 97, 91,1.89,'Normal Sinus Rhythm'),
    createData('10/1/2023, 6PM', '129/89', 99, 99.2, 93,1.67,'Normal Sinus Rhythm'),
];

export default function HealthVitalTable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date & Time</TableCell>
            <TableCell align="center">Blood Pressure (Arm)</TableCell>
            <TableCell align="center">Temparature (°C)</TableCell>
            <TableCell align="center">Sp02</TableCell>
            <TableCell align="center">Weight(Kg)</TableCell>
            <TableCell align="center">Height(Meter)</TableCell>
            <TableCell align="center">ECG</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.date}
              </TableCell>
              <TableCell align="center">{row.bp}</TableCell>
              <TableCell align="center">{row.temp}</TableCell>
              <TableCell align="center">{row.spo2}</TableCell>
              <TableCell align="center">{row.weight}</TableCell>
              <TableCell align="center">{row.height}</TableCell>
              <TableCell align="center">{row.ecg}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
