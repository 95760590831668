import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(
  date: string,
  height: number,
  weight: number,
  bmi: number,
  armbp: number,
  ecg: String
) {
  return { date, height, weight, bmi, armbp,ecg };
}

const rows = [
    createData('10/1/2023, 8AM', 1.72, 72, 14, 4,'Normal Sinus Rhythm'),
    createData('10/1/2023, 10AM', 1.72, 78, 12, 4,'Normal Sinus Rhythm'),
    createData('10/1/2023, 1PM', 1.72, 52, 14, 6,'Normal Sinus Rhythm'),
    createData('10/1/2023, 4PM', 1.72, 92, 12, 4,'Normal Sinus Rhythm'),
    createData('10/1/2023, 6PM', 1.72, 81, 15, 3, 'Normal Sinus Rhythm'),
];

export default function HealthReportTable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date & Time</TableCell>
            <TableCell align="center">Height(Meter)</TableCell>
            <TableCell align="center">Weight(Kg)</TableCell>
            <TableCell align="center">BMI</TableCell>
            <TableCell align="center">Arm BP</TableCell>
            <TableCell align="center">ECG</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.date}
              </TableCell>
              <TableCell align="center">{row.height}</TableCell>
              <TableCell align="center">{row.weight}</TableCell>
              <TableCell align="center">{row.bmi}</TableCell>
              <TableCell align="center">{row.armbp}</TableCell>
              <TableCell align="center">{row.ecg}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
