import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Url from "../../APIConfig";
import { setupInterceptorsTo } from "reduxStore/Authentication/Interceptors";
import { JwtTokenAuthBody, LoginRes } from "./Model";

type InitialState = {
  loading: boolean;
  response: LoginRes;
};
const initialState: InitialState = {
  loading: false,
  response: {} as LoginRes,
};
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
};

export const login = createAsyncThunk<LoginRes, JwtTokenAuthBody>(
  "login/login",
  async (payload: JwtTokenAuthBody, { rejectWithValue }) => {
    return await setupInterceptorsTo(axios)
      .post(Url.baseUrl + '/api/token/', payload, { headers })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data.message));
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      login.fulfilled,
      (state, action: PayloadAction<LoginRes>) => {
        state.loading = false;
        state.response = action.payload;
        localStorage.setItem("access", action.payload.access);
        localStorage.setItem("refresh", String(action.payload.refresh));
      }
    );
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.response = {} as LoginRes;
    });
  },
});

export default loginSlice.reducer;
