import * as React from "react";
import {
  FormControl,
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  Avatar,
} from "@mui/material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useDispatch } from "react-redux";
import { AppDispatch } from "reduxStore/store";
import { forgotPassword } from "reduxStore/forgotPassword/forgotPasswordSlice";
import { useAppSelector } from "reduxStore/hooks";
import FeatureImage from "../images/sportswisdom.png";
export default function ForgottenPassword() {
  const dispatch = useDispatch<AppDispatch>();
  // Call Admin Login store
  const forgotPasswordDetails = useAppSelector((state) => state.forgotPassword);

  const [open, setOpen] = React.useState(false);
console.log(open);
  const [email, setEmail] = React.useState("");

  const userForgotPassword = () => {
    dispatch(forgotPassword(email));
  };
  const pageLayout = (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item>
          <img src={FeatureImage} width="60" height="60" alt="" />
        </Grid>

        <Box
          sx={{ border: 2, borderColor: "#000000", borderRadius: 2 }}
          component="form"
          noValidate
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Avatar sx={{ m: 1, bgcolor: "#000000" }}>
              <LockOpenIcon />
            </Avatar>
            <Grid item>
              <Typography variant="subtitle1" sx={{ color: "#000" }}>
              Forgotten Password
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ my: 4, mx: 6 }}>
            <Grid item>
              <FormControl variant="standard" fullWidth>
                <TextField
                  id="userName"
                  label="Email Address"
                  variant="outlined"
                  fullWidth
                  sx={{ mt: "10" }}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </FormControl>
              <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => {
                  userForgotPassword();
                }}
              >
                Send
              </Button>
            </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line no-lone-blocks
    {
      forgotPasswordDetails !== undefined
        ? forgotPasswordDetails.response !== undefined
          ? forgotPasswordDetails.response.message !== undefined
            ? forgotPasswordDetails.response.message!.length > 0
              ? setOpen(true)
              : setOpen(false)
            : setOpen(false)
          : setOpen(false)
        : setOpen(false);
      console.log("watch===", forgotPasswordDetails);
    }
  }, [forgotPasswordDetails]);

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={6}>
        {pageLayout}
      </Grid>
    </Grid>
  );
}
