import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, Link } from "@mui/material";

export default function Rule() {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
      px={2}
      py={2}
    >
      <Grid item xs={6} sm={6} md={4} lg={4}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={4}
        >
          <Grid item>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              GYM RULES & REGULATIONS
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              We strive to uphold a safe, clean and enjoyable environment. Below
              are a few gym rules and regulations that all members must abide
              by.
            </Typography>
            <Typography variant="subtitle1">
              Gym Working Hours: Daily 6 am to 10 pm.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={6} md={8} lg={8}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Summary</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>
                    <Typography>
                      Download the sportsWISDOM mobile app{" "}
                      <Link
                        href="http://bit.ly/3jIXEIy"
                        underline="none"
                        target="_blank"
                      >
                        http://bit.ly/3jIXEIy
                      </Link>
                      and create your profile for regular updates on your
                      exercise schedule.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Carry a sweat towel and use it when necessary.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Sanitise equipments after use. Don't drop them or throw
                      them at the wall.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      No Tobacco, smokeless, or otherwise, allowed. No
                      gum/food/or any beverage but water allowed.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      No children under 18 years old without parental
                      supervision. (Underage waiver must be signed by parent or
                      guardian).
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Do not spend more than 2 hours/ day or 40 hours/ month in
                      the gym. Remember it's not a social event. Concentrate on
                      getting the best on your reps. Do not Gossip or pass on
                      comments on others.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Do not bring in anything valuable (We are not responsible
                      for any lost or stolen items). Lost and found items will
                      be discarded if not claimed within 2 weeks from the day
                      the item was found.
                    </Typography>
                  </li>
                  <li>
                    <Typography>Wear Deodrants.</Typography>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Attire and Footwear</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>
                    <Typography>
                      Proper workout attire (shorts, t-shirts, sweatpants,
                      sweatshirts) should be worn. No inappropriate or vulgar
                      words or graphics may be worn.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      No bare feet are allowed on the floor. Bring your own Gym
                      shoes.
                    </Typography>
                  </li>
                  <li>
                    <Typography>Shirts must be worn at all times.</Typography>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Gym Equipment</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>
                    <Typography>
                      Equipments are available on a “first-come, first-serve”
                      basis. Help others out by offering your spot while resting
                      between sets.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Please wipe down the machines after your workouts using
                      the wipes provided at the facility.
                    </Typography>
                  </li>
                  <li>
                    <Typography>Put weights where they belong.</Typography>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Prohibition</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>
                    <Typography>
                      Food and drinks (except for plain water) is not allowed at
                      the workout area at any time. Water consumed during
                      workout must be in a container with a lid. Any spills must
                      be cleaned immediately.
                    </Typography>
                  </li>
                  <li>
                    <Typography>No smoking or alcohol on premises.</Typography>
                  </li>
                  <li>
                    <Typography>
                      You will not be permitted to use the facility without your
                      scan card during non-staffing hours at any time. Use of
                      card by anyone other than you will cause it to be
                      confiscated. Trespassing action will be enforced.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Under no circumstance is any member to train another
                      member for compensation. If it is determined that paid
                      personal training has been conducted on the premises, the
                      trainer and trainee will each lose their membership.
                    </Typography>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Physical Condition</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>
                    <Typography>
                      Submit your health vitals twice daily before and after
                      your work out.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      You are not allowed to take the training if your BPM /
                      heart rate greater than 100 or Blood pressure greater than
                      135
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Skip day’s training if you are facing any physical
                      discomfort and share the information with the care givers
                      present.
                    </Typography>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Personal Belongings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>
                    <Typography>
                      Please do not bring any valuables to the fitness center as
                      the staffs will not be responsible for any lost or theft
                      of such items/valuables.
                    </Typography>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
