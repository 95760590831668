import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from '../../APIConfig'
import { SignupBody, SignupResponse } from './Model'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'

type InitialState = {
  loading: boolean
  response: SignupResponse
  error: string
}
const initialState: InitialState = {
  loading: false,
  response: {} as SignupResponse,
  error: '',
}
const headers = {
  Accept: 'application/json',
  'Content-type': 'application/json',
}

export const Signup = createAsyncThunk<SignupResponse, SignupBody>(
  'signup/Signup',
  async (payload: SignupBody, { rejectWithValue }) => {
    return await setupInterceptorsTo(axios)
      .post(Url.baseUrl + '/patientapp/email-otp-signup/', payload, { headers })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data.message))
  },
)

const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Signup.pending, (state) => {
      state.loading = true
    })
    builder.addCase(Signup.fulfilled, (state, action: PayloadAction<SignupResponse>) => {
      state.loading = false
      state.response = action.payload
      // localStorage.setItem('access', action.payload.access)
      // localStorage.setItem('id', String(action.payload.user.id))
      // localStorage.setItem('username', String(action.payload.user.username))
      // localStorage.setItem('email', action.payload.user.email)
      state.error = ''
    })
    builder.addCase(Signup.rejected, (state, action) => {
      state.loading = false
      state.response = {} as SignupResponse
      state.error = action.payload! as string
    })
  },
})

export default signupSlice.reducer

