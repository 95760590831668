import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";

const tiers = [
  {
    title: "Monthly Fees",
    subheader: " Starting at just",
    price: "999",
    description: [
      "This is the initial and minimum duration plan of 1 month. Perfect for who are interested in having a trial before a longer commitment. You can select the plan when you are not convinced whether to join with a fitness club or not. ",
    ],
  },
  {
    title: "Quarterly Fees",
    subheader: " Starting at just",
    price: "2799",
    description: [
      "This plan is of 3 months and get assured best fitness training services for whole quarter. When you opt for it, you can be assured of good health with savings.",
    ],
  },
  {
    title: "Half-Yearly Fees",
    subheader: " Starting at just",
    price: "5499",
    description: [
      "This wonderful half-yearly plan suits those who want to get healthy lifestyle and body with a lot of savings. During this 6 months course, you be assured of getting the best guidance to have the dream body.",
    ],
  },
  {
    title: "Annual Fees",
    subheader: " Starting at just",
    price: "9999",
    description: [
      "This yearly plan will definitely give the peace of mind and uninturrupted training for 365 days with the best savings. Get 2 months extra with just paying 10 month’s subscription. This plan is most popular among those who are certain that they need to transform themselves and have a better lifestyle.",
    ],
  },
];

export default function Subscriptions() {
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ pt: 4, pb: 6 }}
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Pricing
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
        >
          Going to the gym and exercising has numerous health benefits such as
          weight loss, toning the body, increasing flexibility, improving
          cardiac health, joint health, strength and stamina.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="lg" component="main">
        <Grid
          container
          spacing={5}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
        >
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === "Enterprise" ? 12 : 6}
              md={3}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  action={tier.title === "Pro" ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: "center",
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light"
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      mb: 2,
                    }}
                  >
                    <Typography variant="h6" color="text.secondary">
                      Rs.
                    </Typography>
                    <Typography
                      component="h2"
                      variant="h3"
                      color="text.primary"
                    >
                      {tier.price}/-
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      ></Container>
    </React.Fragment>
  );
}
