import * as React from "react";
import Grid from "@mui/material/Grid";
import dashboardImage from "../images/dashboard-img.jpg";
import { Avatar, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fetchPreference } from "reduxStore/preference/preferenceSlice";
import { useAppDispatch, useAppSelector } from "reduxStore/hooks";

export default function Dashboard() {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  async function handleSelectPlan() {
    await navigate("/subscriptions");
  }

  async function handleofferPlan() {
    await navigate("/specialoffers");
  }
  React.useEffect(() => {
    dispatch(fetchPreference(localStorage.getItem("access")!));
  }, [dispatch])
  const preferenceRes = useAppSelector((state) => state.preferenceDetails)
  console.log("preferenceRes==", preferenceRes);
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
      px={2}
      py={2}
    >
      <Grid item xs={6} sm={6} md={4} lg={4}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={4}
        >

          <Grid item>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Welcome to sportsWISDOM
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="subtitle1">
              You have not subscribed Yourself,Please select a plan and became a
              member.
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    fontWeight: 'bold',
                  }}
                  onClick={() => handleSelectPlan()}
                >
                  Select a Plan
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    fontWeight: 'bold',
                  }}
                  onClick={() => handleofferPlan()}
                >
                  Special Offer
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={6} md={8} lg={8}
        display={{ xs: "none", lg: "block" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Avatar
              variant={"rounded"}
              alt="The image"
              src={dashboardImage}
              style={{
                width: 750,
                height: 620,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
