import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from '../../APIConfig'
import { OtpGenerateBody, OtpGenerationResponse } from './Model'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'

type InitialState = {
  loading: boolean
  response: OtpGenerationResponse
  error: string
}
const initialState: InitialState = {
  loading: false,
  response: {} as OtpGenerationResponse,
  error: '',
}
const headers = {
  Accept: 'application/json',
  'Content-type': 'application/json',
}

export const Otp = createAsyncThunk<OtpGenerationResponse, OtpGenerateBody>(
  'signup/Otp',
  async (payload: OtpGenerateBody, { rejectWithValue }) => {
    return await setupInterceptorsTo(axios)
      .post(Url.baseUrl + '/patientapp/email-otp-generation/', payload, { headers })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data.message))
  },
)

const otpGenerateSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Otp.pending, (state) => {
      state.loading = true
    })
    builder.addCase(Otp.fulfilled, (state, action: PayloadAction<OtpGenerationResponse>) => {
      state.loading = false
      state.response = action.payload
      state.error = ''
    })
    builder.addCase(Otp.rejected, (state, action) => {
      state.loading = false
      state.response = {} as OtpGenerationResponse
      state.error = action.payload! as string
    })
  },
})

export default otpGenerateSlice.reducer
