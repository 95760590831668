import * as React from "react";
import {
  Grid,
  Box,
  InputLabel,
  Typography,
  FormControl,
  CircularProgress,
  Stack,
  OutlinedInput,
  Button,
  InputAdornment,
  IconButton,
  Link,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import ErrorText from "components/ErrorText";
import ResponseBanner from "components/ResponseBanner";

interface SignInForm {
  email?: string;
  otp?: string;
  showPassword?: boolean;
}

type Props = {
  emailorph: string;
  responseStatus: number;
  updatedDataStateFromOtp: any;
  loadingState: boolean;
  responseErrorMsg: string;
};

export default function SigninwithOtp({
  emailorph,
  responseStatus,
  updatedDataStateFromOtp,
  loadingState,
  responseErrorMsg,
}: Props) {
  const [visibility, setVisibility] = React.useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInForm>();

  const onSubmit = async (data: SignInForm) => {
    updatedDataStateFromOtp(Object.assign(data, { email: emailorph }));
  };

  const [password, setPassword] = React.useState<SignInForm>({
    otp: "",
    showPassword: false,
  });

  const handlePasswordChange =
    (prop: keyof SignInForm) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword({ ...password, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [status, setStatus] = React.useState<number | null>(null);

  React.useEffect(() => {
    setStatus(responseStatus);
  }, [responseStatus]);

  React.useEffect(() => {
    if (status === 1) {
      setVisibility(false);
    }
  }, [status]);

  return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item sx={{ mt: 2 }}>
          <FormControl fullWidth variant="outlined" disabled={visibility}>
            <InputLabel htmlFor="outlined-adornment-password">OTP</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={password.showPassword ? "text" : "password"}
              value={password.otp}
              {...register("otp", {
                required: true,
                maxLength: 3,
              })}
              onChange={handlePasswordChange("otp")}
              endAdornment={
                <InputAdornment
                  position="end"
                  disablePointerEvents={visibility}
                >
                  <IconButton
                    aria-label="toggle otp visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {password.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              fullWidth
              label="OTP"
            />
          </FormControl>
          <Box pt={1}>
            {errors?.otp?.type === "required" && (
              <ErrorText errormessage={"This field is required"} />
            )}
            {errors?.otp?.type === "maxLength" && (
              <ErrorText errormessage={"Please enter 3 digit OTP"} />
            )}
          </Box>
        </Grid>
        <Grid item sx={{ mt: 1 }}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            disabled={visibility}
          >
            {loadingState ? (
              <Stack direction="row" spacing={1}>
                <Typography style={{ textTransform: "none" }}>
                  Log in
                </Typography>
                <Box pl={1}>
                  <CircularProgress color="inherit" size={18} />
                </Box>
              </Stack>
            ) : (
              <Typography style={{ textTransform: "none" }}>Log in</Typography>
            )}
          </Button>
        </Grid>
        <ResponseBanner responseErrorMsg={responseErrorMsg} />
        <Box mt={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Link href="/signup" color="text.secondary">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
            <Grid item>
              <Typography color="text.primary" sx={{ pt: 0.5 }}>
                {"Don't get OTP"}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="small"
                style={{
                  color: "text.secondary",
                  borderColor: "text.primary",
                }}
              >
                {"Resend"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
  );
}
