import * as React from "react";
import {
  Grid,
  Box,
  Collapse,
  Alert,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
  FormControl,
  CircularProgress,
  Stack,
  OutlinedInput,
  Link,
  Button,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAppSelector } from "reduxStore/hooks";
import { useForm } from "react-hook-form";
import { login } from "reduxStore/login/loginSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "reduxStore/store";
import { useNavigate } from "react-router-dom";
import ErrorText from "components/ErrorText";
import { JwtTokenAuthBody } from "reduxStore/login/Model";
import { fetchAdminLoginDetails } from "reduxStore/adminLoginDetails/adminLoginDetailsSlice";
import { LoginBody } from "reduxStore/adminLoginDetails/adminLoginDetailsModel";

interface SignInForm {
  username?: string;
  password?: string;
  showPassword?: boolean;
}

export default function SigninPassword() {
  const dispatch = useDispatch<AppDispatch>();
  let navigate = useNavigate();
  const loginDetails = useAppSelector((state) => state.login);
  const adminLoginDetailsResponse = useAppSelector(
    (state) => state.loginDetailsValue
  );

  console.log("adminLoginDetailsResponse==",adminLoginDetailsResponse);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInForm>();
  console.log("register", register);
  console.log("handleSubmit", handleSubmit);
  console.log(errors);
  const [access, setToken] = React.useState("");

  const onSubmit = async (data: SignInForm) => {
    await dispatch(login(data as JwtTokenAuthBody));
  };

  React.useEffect(() => {
    if (loginDetails?.response?.access !== undefined) {
      setToken(loginDetails?.response?.access);
    }
  }, [loginDetails]);

  React.useEffect(() => {
    if (access !== "") {
      dispatch(fetchAdminLoginDetails({ access: access } as LoginBody));
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access]);

  React.useEffect(() => {
    //setIsLoading(adminLoginDetailsResponse?.loading);
    if (
      adminLoginDetailsResponse?.adminLoginDetailsRes?.user?.id !== undefined
    ) {
      window.location.reload()
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminLoginDetailsResponse]);

  const [username, setEmail] = React.useState<SignInForm>({
    username: "",
  });

  const handleEmailChange =
    (prop: keyof SignInForm) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail({ ...username, [prop]: event.target.value });
    };

  const [password, setPassword] = React.useState<SignInForm>({
    password: "",
    showPassword: false,
  });

  const handlePasswordChange =
    (prop: keyof SignInForm) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword({ ...password, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [open, setOpen] = React.useState(false);
  const closeAlert = () => {
    setOpen(false);
  };
  

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
    >
      <Grid item>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box sx={{ my: 3, mx: 4 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid item>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="username">Username</InputLabel>
                  <OutlinedInput
                    id="username"
                    label="Username"
                    value={username.username}
                    {...register("username", {
                      required: true,
                      pattern:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                    onChange={handleEmailChange("username")}
                    fullWidth
                    // inputProps={{ style: { color: "text.primary" } }}
                  />
                </FormControl>
                <Box pt={2}>
                  {errors?.username?.type === "required" && (
                    <ErrorText errormessage={"Username is required"} />
                  )}
                  {errors?.username?.type === "pattern" && (
                    <ErrorText errormessage={"Input is invalid"} />
                  )}
                </Box>
              </Grid>
              <Grid item sx={{ mt: 2 }}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={password.showPassword ? "text" : "password"}
                    value={password.password}
                    {...register("password", {
                      required: true,
                      minLength: 2,
                    })}
                    onChange={handlePasswordChange("password")}
                    fullWidth
                    // inputProps={{ style: { color: "text.primary" } }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {password.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
                <Box pt={2}>
                  {/* {errors?.password?.type === "required" && (
                    <ErrorText errormessage={"Password is required"} />
                  )} */}
                </Box>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                >
                  {loginDetails.loading === true ? (
                    <Stack direction="row" spacing={1}>
                      <Typography>Sign In</Typography>
                      <Box pl={1}>
                        <CircularProgress color="inherit" size={18} />
                      </Box>
                    </Stack>
                  ) : (
                    <Typography>Sign In</Typography>
                  )}
                </Button>
              </Grid>
              <Box mt={2}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={10}
                >
                  <Grid item>
                    <Link href="/forgotten-password" color="text.secondary">
                      {"Forgot Password"}
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="/signup" color="text.secondary">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Box>
        </Grid>
        <Grid item>
          {open ? (
            <Collapse in={open}>
              <Alert severity="error" onClose={closeAlert}>
                {/* {loginDetails.error.message} */}
              </Alert>
            </Collapse>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}
