import * as React from "react";
import Grid from "@mui/material/Grid";
import { Avatar, } from "@mui/material";
import "react-big-calendar/lib/css/react-big-calendar.css";
import JdFitness from "../images/JDfitness.svg";
export default function FindOrganization() {


  return (
    <Grid
      container
      justifyContent="right"
      alignItems="right"
    >
      <Grid item>
      <Avatar
          variant={"rounded"}
          alt="The image"
          src={JdFitness}
          style={{
            width: '87vw',
            height: 'auto',
          }}
        />
      </Grid>
    </Grid>
  );
}
