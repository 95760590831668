import * as React from "react";
import Grid from "@mui/material/Grid";
import dashboardImage from "../images/dashboard-img.jpg";
import { Avatar, Typography } from "@mui/material";
export default function NeedHelp() {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
      px={2}
      py={2}
    >
      <Grid item xs={6} sm={6} md={4} lg={4}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={4}
        >
          <Grid item>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Need help from sportsWISDOM
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={6} md={8} lg={8}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item>
            <Avatar
              variant={"rounded"}
              alt="The image"
              src={dashboardImage}
              style={{
                width: 750,
                height: 620,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
