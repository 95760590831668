import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { PrivateRoutes } from 'Authentication/PrivateRoutes'
 import Login from 'pages/OnBoarding/index'
import Signup from './pages/OnBoarding/component/SignUp/index'
import Dashboard from './pages/Dashboard'
import Subscriptions from './pages/Subscriptions'
import ForgottenPassword from 'pages/ForgottenPassword'
import Attendance from 'pages/Attendance'
import FindOrganization from 'pages/FindOrganization'
import Schedule from 'pages/Schedule'
import Notification from 'pages/Notification'
import NeedHelp from 'pages/NeedHelp'
import UserProfile from 'pages/UserProfile'
import HealthVitals from 'pages/MonthlyHealthVitals/HealthVitals'
import ExerciseReport from 'pages/MonthlyExerciseReport/ExerciseReport'
import HealthReport from 'pages/MonthlyHealthReport/HealthReport'
import SpecialOffers from 'pages/SpecialOffers'
import Rule from 'pages/Rule'
import SubscriptionList from 'pages/subscriberList'
import SubscriberDetails from 'pages/SubscriberDetails'
import SubscriberStatus from 'pages/SubscriptionStatus'
function Router() {
  const token = localStorage.getItem('access')
  return (
    <div>
      <Routes>
        <Route path="/" element={token ? <PrivateRoutes component={Dashboard} /> : <Login />} />
        <Route
          path="/login"
          element={token ? <PrivateRoutes component={Dashboard} /> : <Login />}
        />
        <Route path="/dashboard" element={<PrivateRoutes component={Dashboard} />} />
        <Route path="/subscriptions" element={<PrivateRoutes component={Subscriptions} />} />
        <Route path="/healthvitals" element={<PrivateRoutes component={HealthVitals} />} />
        <Route path="/exercisereport" element={<PrivateRoutes component={ExerciseReport} />} />
        <Route path="/healthreport" element={<PrivateRoutes component={HealthReport} />} />
        <Route path="/specialoffers" element={<PrivateRoutes component={SpecialOffers} />} />
        <Route path="/attendance" element={<PrivateRoutes component={Attendance} />} />
        <Route path="/schedule" element={<PrivateRoutes component={Schedule} />} />
        <Route path="/notification" element={<PrivateRoutes component={Notification} />} />
        <Route path="/needhelp" element={<PrivateRoutes component={NeedHelp} />} />
        <Route path="/userprofile" element={<PrivateRoutes component={UserProfile} />} />
        <Route path="/findorganization" element={<PrivateRoutes component={FindOrganization} />} />
        <Route path="/subscriptionslist" element={<PrivateRoutes component={SubscriptionList} />} />
        <Route path="/subscriberdetails/:patientId" element={<PrivateRoutes component={SubscriberDetails} />} />
        <Route path="/subscriptionstatus/:patientId?" element={<PrivateRoutes component={SubscriberStatus} />} />
        <Route path="/forgotten-password" element={<ForgottenPassword />} />
        <Route path="/rule" element={<Rule />} />
        <Route path="/signup" element={<Signup />} />
      </Routes>
    </div>
  )
}

export default Router
