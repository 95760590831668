import * as React from "react";
import {
  Grid,
  Box,
  InputLabel,
  Typography,
  FormControl,
  OutlinedInput,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";
import { useForm } from "react-hook-form";
import ErrorText from "components/ErrorText";
import ResponseBanner from "components/ResponseBanner";

interface SignInForm {
  email?: string;
}

type Props = {
  updatedDataState: any;
  loadingState: boolean;
  bannerState: boolean;
  responseStatus: number;
  responseMsg: string;
  responseErrorMsg: string;
};

export default function OtpGeneration({
  updatedDataState,
  loadingState,
  bannerState,
  responseStatus,
  responseMsg,
  responseErrorMsg,
}: Props) {

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInForm>();

  const onSubmit = async (data: SignInForm) => {
    updatedDataState(data);
  };

  const [email, setEmail] = React.useState<SignInForm>({
    email: "",
  });

  const handleEmailOrPhnoChange =
    (prop: keyof SignInForm) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail({ ...email, [prop]: event.target.value });
      };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="username">Email or Phone number</InputLabel>
            <OutlinedInput
              id="username"
              label="Email or Phone number"
              value={email.email}
              {...register("email", {
                required: true,
                pattern: /^(?:\d{10}|\w+@\w+\.\w{2,3})$/,
              })}
              onChange={handleEmailOrPhnoChange("email")}
              fullWidth
            />
          </FormControl>
          <Box pt={1}>
            {errors?.email?.type === "required" && (
              <ErrorText errormessage={"This field is required"} />
            )}
            {errors?.email?.type === "pattern" && (
              <ErrorText errormessage={"Input is invalid"} />
            )}
          </Box>
        </Grid>
        <Grid item sx={{ mt: 1 }}>
          <Button type="submit" fullWidth variant="contained" size="large">
            {loadingState ? (
              <Stack direction="row" spacing={1}>
                <Typography style={{ textTransform: "none" }}>
                  Send OTP
                </Typography>
                <Box pl={1}>
                  <CircularProgress color="inherit" size={18} />
                </Box>
              </Stack>
            ) : (
              <Typography style={{ textTransform: "none" }}>
                Send OTP
              </Typography>
            )}
          </Button>
        </Grid>
        <ResponseBanner
          bannerState={bannerState}
          responseStatus={responseStatus}
          responseMsg={responseMsg}
          responseErrorMsg={responseErrorMsg}
        />
      </form>
    </Box>
  );
}
